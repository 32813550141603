.bar {
    background-color: var(--bar-background);
    color: #fff;
    padding: 10px;
    height: 100vh;
    width: 20%;
    min-width: 20rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid var(--base-secondary);
}

.navs {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
}

.navs a {
    text-decoration: none;
    width: 90%;
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.8rem;
    padding: 0.4rem 1rem;
}

.inactiveNav {
    text-decoration: none;
    padding: 0.6rem 1rem;
    border-radius: var(--common-radious);
    color: var(--base-tertiary);


}

.activeNav {
    background-color: var(--base-secondary);
    color: #fff;
    padding: 0.6rem 1rem;
    border-radius: var(--common-radious);
}

.settings {
    text-decoration: none;
    width: 90%;
    font-size: 1.8rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.4rem 1rem;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
}

.bottomsect {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1.1rem;
    width: 100%;
    height: auto;
}

.switcherCont {
    display: inline-block;
    display: flex;
    flex-direction: row;
    padding-right: 1rem;

}


.user {
    padding: 0.4rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1.1rem;
    width: 100%;
    height: auto;
    margin-bottom: 4rem;
    margin-top: 1rem;
}

.userimg {
    width: 4rem;
    height: 4rem;
    border-radius: 1000rem;
    background-color: var(--base-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #fff;
}

.user_name p:nth-child(1) {
    font-size: 1.8rem;
    font-weight: bold;
}


@media (max-width: 1000px) {
    .user {
        display: none;
    }

    .bar {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 1rem 2rem;
        justify-content: space-around;
        align-items: center;
        border-right: none;
        border-bottom: 1px solid var(--base-secondary);
        height: auto;

    }

    .navs {
        padding-left: 8rem;
        display: flex;
        flex-direction: row;
        flex-grow: 4;
        align-items: center;
        justify-content: flex-start;
        height: auto;
        /*min*/
    }

    .navs a {
        display: inline-block;
        width: auto;
        color: #fff;
        font-weight: lighter;
        border-radius: 1000rem;
        font-size: 1.4rem;
        /*padding : 0.4rem 1rem;*/
    }

    .navs a svg {
        display: none;
    }

    .bottomsect {
        display: inline-block;
        width: auto;
    }

    .switcherCont {
        display: none;
    }
}


@media (max-width: 700px) {
    .navs {
        padding-left: 1rem;
    }
}

@media (max-width: 560px) {
    .navs {
        padding-left: 0rem;
        gap: 0rem;
    }

    .bar {
        padding: 1rem 1rem;
    }
}

@media (max-width: 460px) {
    .navs {

        flex-direction: column;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10001;
        background-color: rgba(0, 0, 0, 0.89);
        align-items: flex-start;
        gap: 1rem;
        padding: 3rem 3rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
    }

    .activeNav {
        width: 100%;
        background-color: var(--base-secondary);
        color: #fff;
        border-radius: var(--common-radious);
    }

    .bar {
        padding: 1rem 1rem;
        justify-content: space-between;
    }
}

.drawer {
    background-color: transparent;
    cursor: pointer;
    color: #fff;
}
