.home {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
}

@media (max-width: 1000px) {
    .home {
        flex-direction: column;
    }
}


