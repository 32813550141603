.main_page {
    background-color: var(--base-primary);
    height: 100vh;
    flex-grow: 1;
    padding: 3rem 4rem;

}


@media (max-width: 1000px) {
    .main_page {
        height: 95vh;
        display: block;
        width: 100%;
        padding: 2rem 3rem;
    }
}

@media (max-width: 800px) {
    .main_page {
        padding: 1rem 3rem;
    }
}

@media (max-width: 500px) {
    .main_page {
        padding: 1rem 1rem;
    }
}

.notaval {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    color: var(--label-primary);
    font-weight: 500;
}