.chart_area {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: auto;

}

.chart_background {
    position: relative;
    width: 100%;
    border-top: 1px solid var(--grid-color);
}

.chart_lines {
    width: 100%;
    height: 4rem;
    border-top: 1px solid var(--grid-color);
}

.chart_lines:nth-child(1) {
    border-top: 0;
}

.bars_cont {
    padding: 0 4%;
    width: 100%;
    height: 100%;
    position: absolute;
    /*background-color: rgba(39, 163, 113, 0.34);*/
    z-index: 100;
    /*    align perfectly to the relative div*/
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
}

@media (max-width: 420px) {

    .chart_lines {
        height: 2rem;
    }
}