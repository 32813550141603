.amount_bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.7rem;
}

.amount_bar p {
    font-size: 2.4rem;
    font-weight: bolder;
    color: var(--label-primary);

}

@media screen and (max-width: 530px) {
    .amount_bar {
        justify-content: center;
    }

}
