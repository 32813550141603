.recent {
    width: 100%;
    border: 1px solid var(--grid-color);
    border-radius: 0.6rem;
    margin-top: 3rem;
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}

.topBar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.4rem 4%;
}

.topBar h3 {
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--label-primary);
}

.date_pick {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    padding: 0.6rem 1.2rem;
    background-color: transparent;
    border: 1px solid var(--border-colors);
    border-radius: 0.6rem;
    color: var(--label-primary);
}

.switchcont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid var(--grid-color);
    padding: 1rem 4%;
    border-bottom: 1px solid var(--grid-color);
}


.switchcont button {
    padding: 0.8rem 1rem;
    border: 0.8px solid var(--border-colors);
    color: var(--label-primary);
}

.button1 {
    background-color: transparent;
    border-top-left-radius: 0.8rem;
    border-bottom-left-radius: 0.8rem;
    cursor: pointer;
}

.button3 {
    background-color: transparent;
    border-top-right-radius: 0.8rem;
    border-bottom-right-radius: 0.8rem;
    cursor: pointer;
}

.button2 {
    background-color: transparent;
    cursor: pointer;
}

.activebtn {
    background-color: var(--base-quaternary)
}

.transactionTable {
    /*    cover remaining page*/
    width: 100%;
    overflow-y: scroll;
}


table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-bottom: 1px solid var(--grid-color);
    border-top: none;
    border-radius: 0.6rem;

}

tr {
    border-bottom: 1px solid var(--grid-color);
}

td {
    padding: 1.4rem 0;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--label-primary);
}


tr td:nth-child(1) {
    padding-left: 4%;
    width: 50%;

}

.index td:nth-child(1) {
    padding-left: 4%;
    width: 40%;

}

.index td:nth-child(4) {
    padding-left: 4%;

}

tr td:nth-child(1) {
    padding-left: 8%;
    width: 40%;
}

tr td:nth-child(2) {
    color: var(--base-tertiary);
}

tr td:nth-child(3) {

    color: var(--base-tertiary);
}

tr td:nth-child(4) {
    color: var(--base-tertiary);
    padding-right: 4%;
}

.index td {
    font-weight: 500;
    color: var(--base-tertiary);
}

.credit {
    color: var(--accent-primary) !important;

}

.logo {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin-right: 1rem;
    /*    minimise image to fit*/
    object-fit: scale-down;
    border: 1px solid var(--grid-color);
    background-color: #eaeaea;
}

.merchcont {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.3rem;

}

.card {
    width: 3rem;
    height: auto;
    border-radius: 0.2rem;
}

.mode {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 15%;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
}

.cardDet {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.cardDet span:nth-child(1) {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--label-primary);
}

@media (max-width: 1000px) {
    .transactionTable {
        overflow-y: scroll;
    }

    .mode {
        padding-left: 0 !important;
    }

    .recent {
        position: absolute;
        top: 55rem;
        left: 0;
        width: 95vw;
        margin: 0 2.5vw;

    }
}


@media (max-width: 800px) {
    .mode {
        padding-left: 0 !important;
    }

    .index td:nth-child(4) {
        padding-left: 0;

    }

    tr {
        width: 100% !important;
    }

    tr td {
        width: auto !important;
        padding: 1.4rem 0;
        margin: 0;
    }


}

@media (max-width: 420px) {

    .transactionTable {
        overflow-y: scroll;
    }

    .switchcont {
        padding: 0.5rem;
    }

    td {
        font-size: 1rem;
        padding: 2rem 0;
    }

    .recent {
        margin-top: 1rem;
        top: 45rem;
    }

    .topBar {
        padding: 0.4rem 2%;
    }

    .date_pick {
        padding: 0.4rem 0.6rem;
    }

    .switchcont {
        padding: 0.5rem 2%;
    }

    .switchcont button {
        padding: 0.2rem 0.6rem;
        font-size: 0.8rem;
    }


}