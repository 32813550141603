button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.6rem;
    cursor: pointer;
}

.primary {
    background-color: var(--accent-primary);
    color: #fff;
    font-size: 1.4rem;
    padding: 0.8rem 1rem;
    border-radius: var(--common-radious);

}

.primary:hover {
    background-color: var(--accent-secondary);
}

.secondary {
    background-color: transparent;
    border: 1.6px solid var(--border-colors);
    color: var(--label-primary);
    font-size: 1.4rem;
    padding: 0.7rem 1rem;
    border-radius: var(--common-radious);
}

.secondary:hover {
    background-color: var(--base-quaternary);
}